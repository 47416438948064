<template>
  <div class="flex-grow container mx-auto pt-8 pb-16 px-4">
    <section>
      <div class="bg-white overflow-hidden">
        <div class="pb-5">
          <img
            class="mx-auto"
            src="@/assets/homeBanner.jpg"
            alt="A banner image with a photo of Jonathan Powell on the left, the words 'Arete Publishing Ltd' and a mountain range in the background."
          />
        </div>
      </div>
    </section>

    <div class="max-w-[550px] mx-auto">
      <h1 class="text-lg md:text-2xl font-semibold mb-6">
        About Me - Jonathan Powell
      </h1>
      <div class="space-y-5">
        <p>
          I was born in 1952 in <strong>Alexandria,Scotland</strong>. Moving
          to<strong> England</strong> with my parents whilst still a toddler. I
          was educated in Essex, leaving school in 1967 aged 15 having achieved
          little academically. I then applied and was subsequently accepted into
          the British Merchant Navy but was &nbsp;disappointingly&nbsp; laid off
          due to the major constriction of that organisation. I subsequently
          re-located &nbsp;to West Sussex spending most of my early career in
          Retail.
        </p>
        <p>
          In the early 1980’s, I was working at a large Heat Exchange Equipment
          Manufacturer as a Costing Clerk when I was introduced to the ‘Computer
          Dept’. Now this department covered about 250 square meters of
          air-conditioned space which housed 6 computers, 5 data entry
          operators, and a couple of white-coated technicians. The operators
          would feed the information in, produce the binary punch cards which
          were then fed into the computers to produce technical data spec sheets
          and management reports. All this could probably be done on a couple of
          pc’s today !
        </p>
        <p>
          Well and truly bitten by the bug, I managed to brow beat the Head Of
          Operations to let me sit the recognised ICL course which I passed
          (Binary recognition along with the obligatory Ink Blot image
          explanation). These were very exciting times with the advent of Home
          Computers such as Dragon 32, Commodore &amp; Sinclair. A friend from a
          nearby factory who had studied Computer sciences suggested we set up
          our own Business Computer supply company with the U.S.P of selling
          them
          <u>with software</u> (Mainly Word Processing &amp; Accounts software).
          There were no Apps / Programs generally available then ! Machines came
          with the Disk Operating System (CPM and PcDOS) a 10mb hard disk and
          that was it. Average sale price to the End User was in the region of
          £15k which included 7 days training ! Although initially successful,
          the rise of High Street Computer shops offering cheap machines with
          low profit margins put paid to many consultative business’s –
          including ours.
        </p>
        <p>
          After drifting from Retailers to Corporate Direct Sales which
          incorporated the usual expectations of O.T.E’s, Company Car and
          ceaseless K.P.I’s. I finally managed to make Senior Accounts Manager
          with a global office supplies company in the beautiful County of
          Northamptonshire. Supplementing my salary and ego with MLM
          (Multi-Level Marketing) products). My income and lifestyle was pretty
          good.
        </p>
        <p>
          In around 2010, I discovered &nbsp;Ebay and something called PLR and
          had decided to try my hand as an Internet marketer. Making all of the
          common mistakes i.e. buying everything available on sight, never
          focussing on any one product or market coupled with the dreaded
          disease of Procrastination!
        </p>
        <p>
          Then in 2011 all my lifestyle choices disappeared overnight!
          &nbsp;Whilst attending yet another sales meeting after a weekend
          conference for the current MLM product, I hit the deck
          <strong>– literally !</strong>
        </p>
        <p>
          I had suffered a mini-stroke, which took a while to diagnose.&nbsp; I
          eventually discovered that I had contracted a form of Vasculitis and
          that I possibly would not survive more than 12 months.. My life
          changed forever that day in so many ways. After one year of treatment,
          I was almost 4 Stone overweight (Due to taking Steroids and other
          drugs on a daily basis) I had no Savings left and my job had come to
          an end. I was suffering with depression which I managed to hide from
          just about everyone.
        </p>
        <p>
          In 2014, I took the decision that I was going to change my life. There
          is a saying “You can’t Change Your Life If You Don’t Change Your
          Thinking “. So I stopped worrying about what I couldn’t do and what I
          didn’t have and began taking action – my new journey of life ( My
          second Chance ).
        </p>
        <p>
          I began to lose weight, and&nbsp; worked for a time in the ‘caring
          profession’ which really taught me about how lucky I am and how much I
          enjoyed contributing to other people’s lives in a positive way. I
          found that the more I could give the better my own life became. I even
          wrote a book (principally for friends and family) on dealing with
          panic attacks, G.A.D and other Stress related illnesses.&nbsp; Don’t
          misunderstand me, life is not perfect and is still a struggle at times
          – but that is normal for most people. Whether you are rich or poor!
        </p>
        <p>
          Along with losing the 4 stone in weight, I began to get a little
          fitter over time and achieved one of my goals by walking the stunning
          Northumberland coast – a distance of 66 miles.
        </p>
        <p>
          I took note of good advice and managed to get a relatively stress free
          job – driving for a local agency. This has given me the time and
          clarity to revisit my aim of being a successful Internet
          Marketer.&nbsp; The goal is not about making more money than I
          actually need, but to use that platform to provide help and assistance
          to those that truly need and deserve it.
        </p>
        <p>
          Just over 12 months ago my prognosis was ‘Stable’ and last month I was
          informed that my illness was in ‘Full Remission’. Whilst I know that
          my condition can never be cured, I actively try to stay positive and
          make every effort to maximise the potential value that I know my life
          has.
        </p>
        <p>
          In the coming months, I intend to re-engineer&nbsp; those products
          which I have invested in to make them the best they can be and provide
          ultimate value to all of my clients.
        </p>
        <p>
          Of course, I now recognise that I am unable to achieve this alone and
          for that reason have enlisted the guidance of my business friends and
          support of my family without whose love I know I couldn’t have made it
          even this far. I wish my readers and followers every success in their
          ventures and look forward to sharing our achievements.
        </p>
        <p>
          <strong
            ><em
              >All of us are in like a little sailboat, and it’s not the blowing
              of the wind that determines your destination. It’s the set of the
              sail. – Jim Rohn.</em
            ></strong
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script setup></script>
<style module></style>
